import Wrapper from '@components/Wrapper/Wrapper'
import type { NextPage } from 'next'
import styles from '@css/Home.module.css'
import Posts from '@components/Posts/Posts'

const Home: NextPage = () => {
  return (
    <Wrapper>
      <header className={styles.header}>
        <h1>Harsh&apos;s Lab</h1>
        <p>
          Realm on the web where I explore wondrous details in polished
          interfaces. Subscribe to the{' '}
          <a
            href='https://buttondown.email/harsh'
            target='_blank'
            rel='noreferrer'
          >
            newsletter
          </a>{' '}
          to get notified about semi-regular posts on new experiments.
        </p>
        <p>
          Set in the{' '}
          <a href='https://rsms.me/inter/' target='_blank' rel='noreferrer'>
            Inter
          </a>
          ,{' '}
          <a
            href='https://klim.co.nz/collections/soehne/'
            target='_blank'
            rel='noreferrer'
          >
            Söhne
          </a>{' '}
          and{' '}
          <a
            href='https://github.com/tonsky/FiraCode'
            target='_blank'
            rel='noreferrer'
          >
            Fira Code
          </a>{' '}
          typefaces. Designed in{' '}
          <a href='https://www.figma.com/' target='_blank' rel='noreferrer'>
            Figma
          </a>{' '}
          , built with{' '}
          <a href='https://nextjs.org/' target='_blank' rel='noreferrer'>
            Next.js
          </a>
          ,{' '}
          <a href='https://framer.com/motion' target='_blank' rel='noreferrer'>
            Framer Motion
          </a>
          ,{' '}
          <a
            href='https://github.com/css-modules/css-modules'
            target='_blank'
            rel='noreferrer'
          >
            CSS modules
          </a>{' '}
          and a focus on simplicity.
        </p>
      </header>
      <main>
        <Posts />
      </main>
    </Wrapper>
  )
}

export default Home
