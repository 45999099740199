import { Dispatch, FC, SetStateAction, useEffect, useRef } from 'react'
import styles from './style.module.css'
import { FiSearch } from 'react-icons/fi/index'
import type { Post } from '@generated/generated'

export const filter = (posts: Post[], query: string) => {
  if (!query) return posts

  return posts?.filter((post) =>
    post.title.toLowerCase().includes(query.toLowerCase())
  )
}

export const Search: FC<{ setInput: Dispatch<SetStateAction<string>> }> = ({
  setInput,
}) => {
  const input = useRef<HTMLInputElement>(null)

  const focus = (event: KeyboardEvent) => {
    if (event.key === '/') {
      event.preventDefault()
      return input.current?.focus()
    }
  }

  useEffect(() => {
    window.addEventListener('keypress', focus)
    return () => window.removeEventListener('keypress', focus)
  }, [])

  return (
    <>
      <div className={styles.input}>
        <FiSearch fontSize={18} />
        <input
          placeholder='Search posts...'
          onChange={(e) => setInput(e.currentTarget.value)}
          ref={input}
        />
        <kbd className={styles.shortcut}>/</kbd>
      </div>
    </>
  )
}
