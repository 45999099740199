import { FC, useEffect, useState } from 'react'
import { Post, Post as PostProps } from '@generated/generated/types'
import { allPosts } from '@generated/generated'
import { format } from 'date-fns'
import styles from './style.module.css'
import Link from 'next/link'
import { filter, Search } from './Search'
allPosts.sort((a, b) => (a.published < b.published ? 1 : -1))

const Posts: FC<{ posts: Post[] | undefined }> = ({ posts }) => {
  const [hover, setHover] = useState(false)
  const [active, setActive] = useState(0)

  return (
    <div className={styles.container}>
      <div
        className={styles.highlighter}
        style={{
          top: active * 60 - 58,
          opacity: hover ? 1 : 0,
        }}
        aria-hidden
      />
      {posts?.map((blog, index) => (
        <Post
          key={index}
          onHover={() => {
            setActive(index + 1)
            setHover(true)
          }}
          onLeave={() => setHover(false)}
          {...blog}
        />
      ))}
    </div>
  )
}

const Post: FC<
  PostProps & {
    onHover: () => void
    onLeave: () => void
  }
> = (props) => {
  return (
    <Link
      href={`/posts/${props.slug}`}
      className={styles.post}
      onMouseOver={props.onHover}
      onMouseLeave={props.onLeave}
    >
      <p>{props.title}</p>
      <div className={styles.info}>
        <p className={styles.content}>
          {Math.round(props.readingTime.minutes)}{' '}
          {Math.round(props.readingTime.minutes) > 1 ? 'minutes' : 'minute'}
          <span className={styles.separator}>•</span>
          {format(new Date(props.published), 'dd/MM')}
        </p>
        <p className={styles.date}>
          {format(new Date(props.published), 'dd/MM/yy')}
        </p>
      </div>
    </Link>
  )
}

const Blog: FC = () => {
  const [filteredPosts, setFilteredPosts] = useState<Post[] | undefined>(
    allPosts
  )
  const [input, setInput] = useState('')
  useEffect(() => setFilteredPosts(filter(allPosts, input)), [input, setInput])

  return (
    <>
      <Search setInput={setInput} />
      <Posts posts={filteredPosts} />
    </>
  )
}

export default Blog
